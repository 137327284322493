<template>
  <div class="splash-screen">
    <img
      src="@/assets/images/cached/cached-logo-green-icon.svg"
      @load="setupAnimation()"
      ref="splashIcon"
      alt="SML"
      class="splash-icon"
      :style="{ marginRight, marginTop, width }"
      :class="{ 'opacity-0': animationStarted }"
    />

    <div class="position-center-y splash-logo-container opacity-0" :class="{ 'opacity-100': animationStarted }">
      <img
        src="@/assets/images/cached/cached-logo-white-text.svg"
        ref="splashLogo"
        alt="SellMyLivestock"
        class="block"
      />

      <div class="mt-8 text-lg flex items-center">
        <div class="loading loading-white" />

        <span class="ml-5">
          <span v-if="$store.state.auth.token">Logging you in</span>
          <span v-else>Welcome, setting up</span>
        </span>
      </div>

      <div v-if="!appIs.online" class="mt-8 text-gray-lighter">
        <FontAwesomeIcon :icon="faExclamationTriangle" class="mr-1" />
        {{ $t('feedback.unableConnectInternet') }}
      </div>
    </div>
  </div>
</template>

<script>
let targetX = 0
let targetY = 0
let targetHeight = 0

import { Plugins } from '@/plugins/native-app/capacitor'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'LayoutNativeSplashScreen',
  data() {
    return {
      faExclamationTriangle,
      marginRight: 0,
      marginTop: 0,
      width: '40%',
      animationStarted: false
    }
  },
  methods: {
    async setupAnimation() {
      await this.$nextTick()

      setTimeout(async () => {
        if (this.$refs.splashLogo) {
          let position = this.$refs.splashLogo.getBoundingClientRect()

          this.width = this.$refs.splashIcon.offsetHeight
          targetHeight = this.$refs.splashLogo.offsetHeight
          targetX = Math.round(position.left)
          targetY = Math.round(position.top)
        }

        const { SplashScreen } = Plugins
        SplashScreen.hide()

        await this.$nextTick()

        this.animationStarted = true
        window.requestAnimationFrame(this.animate)
      }, 500)
    },

    // Animate the top, left, height values of the bigger icon logo until it matches that of the small full logo
    async animate() {
      if (!this.$refs.splashIcon || !this.$refs.splashLogo) {
        return false
      }

      let requiresMoreAnimation = false

      let width = parseInt(this.width)

      if (width >= targetHeight) {
        this.width = `${width - 10}px`
        requiresMoreAnimation = true
      }

      let position = this.$refs.splashIcon.getBoundingClientRect()
      let positionLeft = Math.round(position.left)
      let positionTop = Math.round(position.top)

      if (positionLeft != targetX) {
        let differenceX = positionLeft - targetX
        let adjustXAmount = differenceX - 1 || 1
        this.marginRight = `${parseInt(this.marginRight) + adjustXAmount}px`
        requiresMoreAnimation = true
      }

      if (positionTop != targetY) {
        let differenceY = targetY - positionTop
        let adjustYAmount = differenceY - 1 || 1
        this.marginTop = `${parseInt(this.marginTop) + adjustYAmount}px`
        requiresMoreAnimation = true
      }

      if (requiresMoreAnimation) {
        window.requestAnimationFrame(this.animate)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.splash-screen {
  @apply flex flex-col justify-center items-center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #768e4d;
}

.splash-icon {
  display: block;
  width: 40%;
  max-width: 250px;
  transition: opacity 0.3s;
}

.splash-logo-container {
  @apply absolute flex flex-col w-full items-center justify-center px-6 text-white;
  transition: opacity 1.5s;
}

.powered-by {
  @apply mx-auto block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 35px;
  opacity: 0.8;
}
</style>
