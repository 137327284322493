import { Plugins, platform, StatusBarStyle, isNativeApp } from '@/plugins/native-app/capacitor'

if (isNativeApp && Plugins) {
  const { StatusBar } = Plugins

  StatusBar.setStyle({
    style: StatusBarStyle.Dark
  })

  if (platform === 'ios') {
    // iOS statusbar tap, scroll to top
    window.addEventListener('statusTap', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    })
  }

  // if (platform === 'android') {
  //   StatusBar.setBackgroundColor({
  //     color: '#FFFFFF'
  //   })
  // }
}
