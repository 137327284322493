<template>
  <div
    class="message py-2"
    :class="{
      'message-error': type === 'error',
      'message-information': type === 'information',
      'message-warning': type === 'warning',
      'message-success': type === 'success',
      'border-none': borderless,
      'text-sm px-2 py-1 sm:px-2 sm:py-1': small,
      'sm:py-3 px-3 sm:px-4': !small && !large,
      'pr-6': dismissible,
      'px-4 py-3': large,
      rounded
    }"
    @click="$emit('click')"
  >
    <FontAwesomeIcon v-if="showIcon" :icon="compIcon" class="icon mr-1" fixed-width />

    <slot></slot>

    <a v-if="dismissible" @click="dismiss()">
      <FontAwesomeIcon :icon="faTimes" class="message-close" />
    </a>
  </div>
</template>

<script>
import { faCheckCircle, faExclamationTriangle, faTimes, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'UtilMessage',
  props: {
    type: {
      type: String,
      default: 'information',
      validator: prop => ['information', 'warning', 'error', 'success'].includes(prop)
    },
    large: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    name: String
  },
  data() {
    return {
      faTimes
    }
  },
  created() {
    if (this.compErrorWithDismissible) {
      console.error('You must provide a name to enable dismissible')
    }
  },
  computed: {
    compErrorWithDismissible() {
      return this.dismissible && !this.name
    },

    compIcon() {
      let icon = faInfoCircle

      switch (this.type) {
        case 'warning':
          icon = faExclamationTriangle
          break

        case 'error':
          icon = faTimesCircle
          break

        case 'success':
          icon = faCheckCircle
          break
      }

      return icon
    }
  },
  methods: {
    dismiss() {
      if (this.compErrorWithDismissible) {
        return false
      }

      this.$analytics.addEvent({
        category: 'settings',
        action: 'Dismissed message',
        label: this.name
      })

      this.$emit('dismiss', this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  border-width: 2px;
  position: relative;
  @apply rounded;
}

.message.message-warning {
  @apply bg-warning-lighter;
  @apply border-warning;

  .icon {
    @apply text-warning;
  }
}

.message.message-error {
  @apply bg-danger-lighter;
  @apply border-danger;
  @apply text-primary;

  .icon {
    @apply text-danger;
  }
}

.message.message-information {
  @apply bg-information-lighter;
  @apply border-information;
  @apply text-primary;

  .icon {
    @apply text-information;
  }
}

.message.message-success {
  @apply bg-success-lighter;
  @apply border-success;
  @apply text-primary;

  .icon {
    @apply text-success;
  }
}

.message a {
  color: inherit;
}

.message-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
</style>
