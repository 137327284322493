<template>
  <header :class="{ sticky: !appIs.online || $breakpoint.smAndDown, 'mobile-menu-open': mobileMenuOpen }">
    <div v-if="$breakpoint.mdAndUp && !appIs.mobileOrNative">
      <div class="bg-brand-darker px-4 py-2 border-b border-brand-lighter flex justify-between items-center relative z-40 font-medium">
        <UtilTextLink
          to="/help"
          class="text-white text-sm no-underline"
        >
          Need some help?
        </UtilTextLink>

        <!-- Not logged in -->
        <div v-if="!appIs.auth" class="flex">
          <!-- Blog -->
          <UtilTextLink
            to="/blog"
            class="text-white no-underline flex items-center text-sm mr-6"
          >
            <FontAwesomeIcon :icon="faPen" class="text-primary-button mr-2" />
            Blog &amp; Podcasts
          </UtilTextLink>

          <UtilTextLink
            @click="$modal.open('ModalAuthRegister')"
            class="text-white no-underline flex items-center text-sm mr-6"
          >
            <FontAwesomeIcon :icon="faUser" class="text-primary-button mr-2" />
            {{ $t('auth.register') }}
          </UtilTextLink>

          <UtilTextLink
            @click="
              $store.dispatch('modal/showModal', {
                modalName: 'ModalAuthLogin',
                modalData: { redirectToDashboard: false }
              })
            "
            class="text-white no-underline flex items-center text-sm"
          >
            <FontAwesomeIcon :icon="faUser" class="text-primary-button mr-2" />
            {{ $t('auth.login') }}
          </UtilTextLink>
        </div>

        <!-- Logged in -->
        <div v-else class="flex">
          <!-- Blog -->
          <UtilTextLink
            to="/blog"
            class="text-white no-underline flex items-center text-sm mr-6"
          >
            <FontAwesomeIcon :icon="faPen" class="text-primary-button mr-2" />
            Blog
          </UtilTextLink>

          <!-- Watchlist -->
          <UtilTextLink
            to="/account/listings/saved/watchlist"
            class="text-white no-underline flex items-center text-sm mr-6"
          >
            <FontAwesomeIcon :icon="faStar" class="text-primary-button mr-2" />
            Watchlist
          </UtilTextLink>

          <!-- Messages -->
          <UtilTextLink
            to="/account/messages"
            class="text-white no-underline flex items-center text-sm mr-6"
          >
            <FontAwesomeIcon :icon="faEnvelope" class="text-primary-button mr-2" />
            {{ $t('nav.messages') }}
            <UtilBadgeCount v-if="app.unread.total" :count="app.unread.total" unread class="ml-1" />
          </UtilTextLink>

          <!-- My account -->
          <div class="account-menu-wrapper">
            <UtilTextLink
              to="/account/dashboard"
              class="text-white no-underline flex items-center text-sm"
            >
              <FontAwesomeIcon v-if="app.unread.total === 0" :icon="faUser" class="text-primary-button mr-2" />

              <UtilBadgeCount v-else :count="app.unread.total" unread class="text-primary-button mr-2" />
              My account
            </UtilTextLink>

            <div class="account-menu">
              <WidgetUac
                @click="$router.push('/profile')"
                :user="app.user"
                :company="app.company"
                :showFlag="false"
                :bordered="false"
                :lazyLoad="false"
                linkToProfile
                compact
                class="text-sm px-2 cursor-pointer"
              />

              <UtilDivider class="mt-2" />

              <LayoutAccountNav linkClass="pl-4 mr-4 py-2" :showActiveIndicator="false" />
            </div>
          </div>

          <LayoutCartPreview />
        </div>
      </div>

      <!-- SubNav / Desktop Mega Menu -->
      <LayoutSubNav @setOverlay="setOverlay"/>

      <transition name="fade">
        <div v-if="showOverlay" class="nav-overlay" />
      </transition>
    </div>

    <div v-else>
      <LayoutNavMobile />
      <LayoutCartPreview v-if="appIs.auth" />
    </div>

    <UtilWarningAdmin v-if="appIs.loggedInAsAnotherUser || appIs.loggedInAsHelpdesk" />

    <UtilWarningStaging v-if="appIs.staging" />

    <UtilWarningOffline v-if="!appIs.online" />

    <UtilMaintenanceBypassWarning v-if="$store.state.app.isMaintenanceModeBypassed" />

    <div v-if="$store.state.app.globalMessage" class="bg-blue-200 py-1 flex items-center justify-center">
      {{ $store.state.app.globalMessage }}
    </div>
  </header>
</template>

<script>
  import UtilWarningOffline from '@/components/utils/UtilWarningOffline'
  import UtilWarningAdmin from '@/components/utils/UtilWarningAdmin'
  import UtilWarningStaging from '@/components/utils/UtilWarningStaging'
  import UtilMaintenanceBypassWarning from '@/components/utils/UtilMaintenanceBypassWarning'
  import LayoutCartPreview from '@/components/layout/LayoutCartPreview'
  import LayoutSubNav from '@/components/layout/navigation/LayoutSubNav'
  import LayoutNavMobile from '@/components/layout/navigation/LayoutNavMobile'

  import UtilTextLink from '@/components/utils/UtilTextLink'
  import {faEnvelope, faPen, faStar, faUser} from '@fortawesome/pro-solid-svg-icons'
  import UtilDivider from '@/components/utils/UtilDivider'
  import WidgetUac from '@/components/widgets/WidgetUac'
  import LayoutAccountNav from '@/components/layout/navigation/LayoutAccountNav'
  import UtilBadgeCount from '@/components/utils/UtilBadgeCount'


  export default {
  name: 'LayoutNavTop',
  components: {
    UtilWarningOffline,
    UtilWarningAdmin,
    UtilWarningStaging,
    UtilMaintenanceBypassWarning,
    LayoutSubNav,
    LayoutNavMobile,
    LayoutCartPreview,
    UtilTextLink,
    UtilDivider,
    WidgetUac,
    LayoutAccountNav,
    UtilBadgeCount
  },
  data() {
    return {
      mobileMenuOpen: false,
      showOverlay: false,
      faUser,
      faStar,
      faEnvelope,
      faPen
    }
  },
  methods: {
    setOverlay(value) {
      this.showOverlay = value
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  @apply bg-brand;
  box-shadow: 0 2px 2px 0 rgba(51, 65, 80, 0.03), 0 0 2px 0 rgba(51, 65, 80, 0.03);
  z-index: 9;
}

header.sticky {
  position: sticky;
  top: 0;
}

header.mobile-menu-open {
  z-index: 21;
}

#app.ios header {
  padding-top: calc(constant(safe-area-inset-top)); /* Status bar height on iOS 11.0 */
  padding-top: calc(env(safe-area-inset-top)); /* Status bar height on iOS 11+ */
}

.account-menu-wrapper {
  position: relative;
  z-index: 6;
  display: flex;

  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 20px;
    left: -100px;
    right: -20px;
    height: 40px;
    cursor: pointer;
    z-index: 6;
  }

  &:hover:after {
    display: block;
  }
}

.account-menu {
  @apply py-2 shadow-2xl text-primary text-left rounded;
  display: none;
  min-width: 250px;
  position: absolute;
  top: 48px;
  right: 0px;
  background: #fff;
  z-index: 7;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 60px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
}

.account-menu-wrapper:hover .account-menu {
  display: block;
}

.nav-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: -1;
  background: rgba(0,0,0,.6);
}

</style>
